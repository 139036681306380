// exports.APP_URL = "http://localhost:3000";
exports.APP_URL = "https://aungthamardi.stagingb2b.edkamm.com";
exports.BACKEND_URL = 'https://aungthamardi.staging.apib2b.edkamm.com/api/v1';
// exports.BACKEND_URL = ''
// exports.BACKEND_URL = "http://localhost:3004/api/v1";


exports.GeneralStatus = {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
};

exports.TransactionTypes = [
    {
        id: "Deposit",
        type: "Deposit",
    },
    {
        id: "Withdrawl",
        type: "Withdrawl",
    },
];

exports.mainAccountsWithPaidThrough = [
    "Other Income",
    "Capital",
    "Expense",
    "Fix Assets",
    "Current Assets",
    "Current Liability",
];

exports.incomeMainAccounts = [
    "Other Income",
    "Capital",
    "Current Liability",
    "Long Term Liability",
];
exports.expenseMainAccounts = [
    "Expense",
    "Current Assets",
    "Fix Assets",
    "Cost of Good Sold",
];
